<template>
  <div class="container">
    <!-- Language -->
    <div style="position: fixed; right: 20px; top: 20px">
      <div class="btn-login" style="border-radius: 5px;">
      </div>
    </div>
    <!-- Login form -->
    <div class="login-container">
      <div style="text-align: left;">
        <span>{{ $t("register.emailIsNotRegistered") }}</span>
        <span style="color: #26BA50; display: block;">{{ email }}</span>
        <span style="font-size: small;">{{
          $t("register.setAccessPassword")
        }}</span>
        <form @submit="checkForm">
          <div style="position: relative;">
            <!-- Password -->
            <div class="form-group p-0 m-0">
              <input
                v-model="password"
                :type="isShowPassword ? 'text' : 'password'"
                class="form-control mt-3"
                :placeholder="$t('register.password')"
                aria-label="Password"
                aria-describedby="basic-addon1"
                v-bind:class="{ 'form-control': true, 'is-invalid' : !validRequire(password) && fieldBlured}"
                v-on:blur="fieldBlured = true"
              />
              <div class="invalid-feedback" style="text-align: left;">{{ $t('checkForm.invalidPassword') }}</div>
              <div
                v-show="!isShowPassword && validRequire(password)"
                style="position: absolute; right: 0; top: 20%; right: 5px;"
                @click="isShowPassword = !isShowPassword"
              >
                <i class="fas fa-eye-slash"></i>
              </div>
              <div
                v-show="isShowPassword && validRequire(password)"
                style="position: absolute; right: 0; top: 20%; right: 5px;"
                @click="isShowPassword = !isShowPassword"
              >
                <i class="fas fa-eye"></i>
              </div>
            </div>
          </div>
          <div class="form-group p-0 m-0">
            <!-- <div class="col-7"> -->
              <!-- Last name -->
              <div class="form-group p-0 m-0">
                <input
                  v-model="fullName"
                  type="text"
                  class="form-control mt-3"
                  :placeholder="$t('register.fullName')"
                  aria-describedby="basic-addon1"
                  v-bind:class="{ 'form-control': true, 'is-invalid' : !validRequire(fullName) && fieldBlured}"
                  v-on:blur="fieldBlured = true"
                />
                <div class="invalid-feedback" style="text-align: left;">{{ $t('checkForm.invalidFullName') }}</div>
              </div>
            <!-- </div> -->
            <!-- <div class="col-5">
              <div class="form-group p-0 m-0">
                <input
                  v-model="firstName"
                  type="text"
                  class="form-control mt-3"
                  :placeholder="$t('register.firstName')"
                  aria-label="firstName"
                  aria-describedby="basic-addon1"
                  v-bind:class="{ 'form-control': true, 'is-invalid' : !validRequire(firstName) && fieldBlured}"
                  v-on:blur="fieldBlured = true"
                />
                <div class="invalid-feedback" style="text-align: left;">{{ $t('checkForm.invalidFirstName') }}</div>
              </div>
            </div> -->
          </div>
          <!-- First name -->
          <div class="form-group p-0 m-0">
            <input
              v-model="phone"
              type="text"
              class="form-control mt-3"
              :placeholder="$t('register.phone')"
              aria-label="phone"
              aria-describedby="basic-addon1"
              v-bind:class="{ 'form-control': true, 'is-invalid' : !validRequire(phone) && fieldBlured}"
              v-on:blur="fieldBlured = true"
            />
            <div class="invalid-feedback" style="text-align: left;">{{ $t('checkForm.invalidPhone') }}</div>
          </div>
          <div class="form-group p-0 m-0">
            <input
              v-model="position"
              type="text"
              class="form-control mt-3"
              :placeholder="$t('register.position')"
              aria-label="position"
              aria-describedby="basic-addon1"
              v-bind:class="{ 'form-control': true, 'is-invalid' : !validRequire(position) && fieldBlured}"
              v-on:blur="fieldBlured = true"
            />
            <div class="invalid-feedback" style="text-align: left;">{{ $t('checkForm.invalidPosition') }}</div>
          </div>
          <div class="form-group p-0 m-0">
            <input
              v-model="companyName"
              type="text"
              class="form-control mt-3"
              :placeholder="$t('register.companyName')"
              aria-label="companyName"
              aria-describedby="basic-addon1"
              v-bind:class="{ 'form-control': true, 'is-invalid' : !validRequire(companyName) && fieldBlured}"
              v-on:blur="fieldBlured = true"
            />
            <div class="invalid-feedback" style="text-align: left;">{{ $t('checkForm.invalidCompanyName') }}</div>
          </div>
          <button
            type="submit"
            class="btn-login btn mt-3"
            style="width: 100%; color: #ffffff;"
            v-on:click.stop.prevent="checkForm"
          >
            {{ $t("register.title") }}
          </button>
        </form>
        <div class="pt-2">
          <label for="" class="text-color meeting-font-medium float-right" @click="onClickCallBackLogin">{{$t('register.callBackLogin')}}

          </label>
          </div>
      </div>
    </div>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    ></snack-bar>
    <loading-bar :isLoading="isLoading"></loading-bar>
  </div>
</template>
<script>
import functionUtils from "utils/functionUtils";
import { mapGetters, mapActions } from "vuex";
export default {
  data: () => ({
    isLoading: false,
    username: "",
    password: "",
    email: "",
    phone: "",
    fullName: "",
    position: "",
    companyName: "",
    locale: "vi",
    isShowPassword: false,
    valid: false,
    fieldBlured: false,
    forICTDongnai: process.env.VUE_APP_IS_FOR_DONGNAI === "true"
  }),
  computed: {
    ...mapGetters(["GET_SNACK_BAR"])
  },
  methods: {
    /**
     * Check form
     */
    checkForm: function() {
      this.fieldBlured = true
      if (this.validRequire(this.password) && this.validRequire(this.fullName) && this.validRequire(this.position) && this.validRequire(this.companyName)) {
        this.onRegister()
      }
    },
    /**
     * Valid require
     */
    validRequire: function (fieldRequire) {
      if (fieldRequire != '') {
        return true
      } else {
        return false
      }
    },
    /**
     * Change language
     */
    changeLanguage: function() {
      let locale = this.$store.state.language.selectedLocale;
      if (locale == "en") {
        this.$i18n.locale = "vi";
        this.locale = "vi";
      } else {
        this.$i18n.locale = "en";
        this.locale = "en";
      }
      this.$store.dispatch("changeLanguage", this.locale);
    },
    /**
     * On register
     */
    onRegister: function() {
      let filter = {
        username: this.username,
        password: this.password,
        email: this.email,
        phone: this.phone,
        firstName: functionUtils.getFirstLastName(this.fullName).firstName,
        lastName: functionUtils.getFirstLastName(this.fullName).lastName,
        position: this.position,
        companyName: this.companyName,
        site_name: this.$t('common.appTitle')
      };
      this.isLoading = true;
      this.REGISTER(filter).then(
        function(res) {
          let user_id = res.data.user_id
          let domain = window.location.protocol + '//' + window.location.host + process.env.VUE_APP_BASE_PATH
          filter['id'] = user_id
          filter['domain'] = domain
          if (this.forICTDongnai) {
            this.SEND_REGISTERED_ACCOUNT_FOR_ICT_DONGNAI(filter)
          }else{
            this.SEND_EMAIL_ACTIVE_ACCOUNT(filter)
          }
          this.$router.push({
            name: "login",
            query: {
              email: this.email
            }
          });
          this.isLoading = false;
        }.bind(this)
      ).catch(
        function () {
          this.isLoading = false
        }.bind(this)
      )
    },
    onClickCallBackLogin: function(){
      this.$router.push({
        name: "checkLogin"
      });
    },
    ...mapActions(["REGISTER", "ON_SHOW_TOAST", "SEND_REGISTERED_ACCOUNT_FOR_ICT_DONGNAI", "SEND_EMAIL_ACTIVE_ACCOUNT"])
  },
  mounted() {
    this.email = this.$route.query.email;
  }
};
</script>
<style lang="scss" scoped>
@import "../../style/color.scss";
.login-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 350px;
}
.btn-login {
  background: -moz-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -o-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -ms-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.3, $light-primary),
    color-stop(0.7, $primary)
  );
  border-radius: 5px;
}
.text-color{
  color: $primary;
}
.text-color:hover{
  text-decoration: underline;
  cursor: pointer;
}
</style>
